import { combineReducers } from 'redux';
import products from './products';
import basket from './basket';
import storeSettings from './store-settings';
import calendar from './calendar';

const rootReducer = combineReducers({
  products,
  basket,
  storeSettings,
  calendar,
});

export default rootReducer;
