import { put, takeLatest, all } from 'redux-saga/effects';
import { INITIALIZE_APPLICATION, requestProducts } from '../actions';
import { SagaIterator } from 'redux-saga';
import { requestStoreSettings } from '../actions/store-settings';

function* initialize(): SagaIterator {
  yield put(requestProducts());
  yield put(requestStoreSettings());
}

export function* watchInitializeApp(): SagaIterator {
  yield takeLatest(INITIALIZE_APPLICATION, initialize);
}

export default function* initializeSaga(): unknown {
  yield all([watchInitializeApp()]);
}
