import models from '../types/models';
import server from '../types/server';
import moment from 'moment';
import 'moment/locale/en-gb';

const serverResponseOpeningRangeToHoursRange = (openingHours: server.OpeningRange): models.HoursRange => ({
  startTime: openingHours.from,
  endTime: openingHours.to,
});

const serverResponseOpeningHoursToOpeningHours = (calendar: server.Calendar): models.OpeningHours[] => {
  Object.keys(calendar);
  const openingHours: models.OpeningHours[] = [];
  for (const day in calendar) {
    openingHours.push({
      day: moment().locale('en-gb').day(day).isoWeekday() % 7,
      openingHours: calendar[day].map((openingHoursRange) => serverResponseOpeningRangeToHoursRange(openingHoursRange)),
    });
  }
  return openingHours;
};

const serverResponseSpecialOpeningHoursToSpecialOpeningHours = (
  responseItem: server.SpecialOpeningHour,
): models.SpecialOpeningHours => ({
  date: new Date(responseItem.date),
  openingHours: responseItem.ranges.map((range) => serverResponseOpeningRangeToHoursRange(range)),
  open: responseItem.open > 0,
});

export default { serverResponseOpeningHoursToOpeningHours, serverResponseSpecialOpeningHoursToSpecialOpeningHours };
