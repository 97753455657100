import React from 'react';
import states from '../types/states';
import ActionBar from '../components/ActionBar';
import PersonalDetails from '../components/PersonalDetails';
import FetchTime from '../components/FetchTime';
import { validateCheckoutPage } from '../components/utlils';
import { Link } from 'react-router-dom';

const Checkout: React.FC<states.BasketState> = (props: states.BasketState) => {
  const handleNavigationClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, component: string): void => {
    if (!validateCheckoutPage(props, component)) {
      e.preventDefault();
    }
  };
  return (
    <div className="checkout">
      <h1 className="checkout__title">Afrekenen</h1>
      <nav className="checkout__navigation">
        <Link to="/afrekenen" className="checkout__navigation-item checkout__navigation-item--active">
          Gegevens
        </Link>
        <Link
          to="/afrekenen/controleren"
          onClick={(e): void => handleNavigationClick(e, 'controleren')}
          className="checkout__navigation-item"
        >
          Controleren
        </Link>
        <Link
          to="/afrekenen/controleren"
          onClick={(e): void => handleNavigationClick(e, 'klaar')}
          className="checkout__navigation-item"
        >
          Klaar
        </Link>
      </nav>
      {validateCheckoutPage(props, 'afrekenen') && (
        <div className="checkout__content">
          <PersonalDetails {...props.personalDetails} />
          <FetchTime {...props.fetchTime} />
        </div>
      )}
      <ActionBar
        previous={{
          link: '/basket',
          title: 'Terug',
        }}
        next={{
          link: '/afrekenen/controleren',
          title: 'Verder',
        }}
        requireValidation={true}
        nextIsValid={validateCheckoutPage(props, 'controleren')}
      />
    </div>
  );
};

export default Checkout;
