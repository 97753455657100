import React, { useRef } from 'react';
import propTypes from '../types/props';
import ProductBlock from './ProductBlock';
import ProductConfiguration from './ProductConfiguration';
import { setSelectedProduct } from '../actions';
import { useDispatch } from 'react-redux';
import models from '../types/models';

const Product: React.FC<propTypes.Product> = (props: propTypes.Product) => {
  const { product, selected, orderLine } = props;
  const productBlock = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleProductExpandClick = (): void => {
    dispatch(setSelectedProduct(selected ? ({} as models.Product) : product));
    const current = productBlock.current || ({} as HTMLDivElement);
    if (window.outerWidth < 1200) {
      document.documentElement.requestFullscreen();
    }
    current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="product" ref={productBlock}>
      <ProductBlock product={product} add={!selected} handleButtonClick={(): void => handleProductExpandClick()} />
      {selected && <ProductConfiguration {...orderLine} />}
    </div>
  );
};

export default Product;
