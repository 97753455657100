export const INITIALIZE_APPLICATION = 'INITIALIZE_APPLICATION';

// PRODUCTS
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const UPDATE_SELECTED_PRODUCT_INGREDIENT = 'UPDATE_SELECTED_PRODUCT_INGREDIENT';
export const UPDATE_SELECTED_PRODUCT_OPTION = 'UPDATE_SELECTED_PRODUCT_OPTION';
export const UPDATE_SELECTED_PRODUCT_QUANTITY = 'UPDATE_SELECTED_PRODUCT_QUANTITY';

// ORDERS
export const ADD_ORDER_LINE = 'ADD_ORDER_LINE';
export const REMOVE_ORDER_LINE = 'REMOVE_ORDER_LINE';
export const UPDATE_ORDER_LINE = 'UPDATE_ORDER_LINE';
export const REQUEST_SUBMIT_ORDER = 'REQUEST_SUBMIT_ORDER';
export const RECEIVE_SUBMIT_ORDER = 'RECEIVE_SUBMIT_ORDER';

// PERSONAL DETAILS FORM
export const UPDATE_PERSONAL_DETAILS_FORM = 'UPDATE_PERSONAL_DETAILS_FORM';

// FETCH DATE
export const UPDATE_FETCH_DATE = 'UPDATE_FETCH_DATE';
export const UPDATE_FETCH_RANGE = 'UPDATE_FETCH_RANGE';

// OPENING HOURS
export const REQUEST_OPENINGHOURS = 'REQUEST_OPENINGHOURS';
export const RECEIVE_OPENINGHOURS = 'RECEIVE_OPENINGHOURS';
export const FETCH_OPENINGHOURS_ERROR = 'FETCH_OPENINGHOURS_ERROR';

// SPECIAL OPENINGS
export const REQUEST_SPECIAL_OPENINGHOURS = 'REQUEST_SPECIAL_OPENINGHOURS';
export const RECEIVE_SPECIAL_OPENINGHOURS = 'RECEIVE_SPECIAL_OPENINGHOURS';
export const FETCH_SPECIAL_OPENINGHOURS_ERROR = 'FETCH_SPECIAL_OPENINGHOURS_ERROR';

// STORE SETTTINGS
export const REQUEST_STORE_SETTINGS = 'REQUEST_STORE_SETTINGS';
export const RECEIVE_STORE_SETTINGS = 'RECEIVE_STORE_SETTINGS';
export const FETCH_STORE_SETTINGS_ERROR = 'FETCH_STORE_SETTINGS_ERROR';

// ORDER QUANTITIES
export const REQUEST_ORDER_QUANTITIES = 'REQUEST_ORDER_QUANTITIES';
export const RECEIVE_ORDER_QUANTITIES = 'RECEIVE_ORDER_QUANTITIES';
export const FETCH_ORDER_QUANTITIES_ERROR = 'FETCH_ORDER_QUANTITIES_ERROR';

// TOTAL
export const UPDATE_TOTAL_PRICE = 'UPDATE_TOTAL_PRICE';
