import React, { useState, useRef } from 'react';
import models from '../types/models';
import DatePicker from './DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import states from '../types/states';
import { format, getDay } from 'date-fns';
import { range, some } from 'lodash';
import { generateKey, joinClassNames } from './utlils';
import moment from 'moment';
import { updateFetchRange } from '../actions';

const FetchTime: React.FC<models.FetchDate> = (props: models.FetchDate) => {
  const { date } = props;
  const calendar = useSelector((state: states.State) => state.calendar);
  const timeRange = useSelector((state: states.State) => state.basket.fetchTime.range);
  const timeSlots = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const specialOpeningHours = calendar.specialOpeningHours.filter(
    (t) => format(t.date, 'dd/MM/yyyy') === format(date, 'dd/MM/yyyy'),
  );
  const casualOpeningHours = calendar.casualOpenings.filter((t) => getDay(date) === t.day);
  const openingHours =
    specialOpeningHours.length > 0
      ? specialOpeningHours[0].openingHours
      : casualOpeningHours.length > 0
      ? casualOpeningHours[0].openingHours
      : [];
  const allowedHour = (hour: number): boolean => {
    const seconds = moment(`${hour < 10 ? 0 : ''}${hour}:00`, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    const currentSeconds = moment(new Date()).diff(moment().startOf('day'), 'seconds');
    return (
      some(openingHours, (t) => t.startTime <= seconds && t.endTime > seconds) &&
      (moment().startOf('day') < moment(date).startOf('day') || currentSeconds < seconds + 2700)
    );
  };

  const selectedRange = (hour: number, minutes: number): boolean => {
    const seconds = moment(`${hour < 10 ? 0 : ''}${hour}:${minutes < 10 ? 0 : ''}${minutes}`, 'HH:mm').diff(
      moment().startOf('day'),
      'seconds',
    );
    return timeRange.startTime <= seconds && timeRange.endTime > seconds;
  };

  const showTimeSlot = (hour: number, minutes: number): boolean => {
    const seconds = moment(`${hour < 10 ? 0 : ''}${hour}:${minutes < 10 ? 0 : ''}${minutes}`, 'HH:mm').diff(
      moment().startOf('day'),
      'seconds',
    );
    const currentSeconds = moment(new Date()).diff(moment().startOf('day'), 'seconds');
    return (
      some(openingHours, (t) => t.startTime <= seconds && t.endTime > seconds) &&
      (moment().startOf('day') < moment(date).startOf('day') || currentSeconds < seconds)
    );
  };

  const [rangeHoursExpanded, setRangeHoursExpanded] = useState(false);
  const [selectedHour, setSelectedHour] = useState(-1);
  const handleRangeHourClick = (i: number): void => {
    setRangeHoursExpanded(false);
    setSelectedHour(i);
    dispatch(updateFetchRange(i, 0));
    setTimeout(() => {
      if (timeSlots.current) {
        timeSlots.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);
  };

  const handleRangeClick = (hour: number, minutes: number): void => {
    dispatch(updateFetchRange(hour, minutes));
  };
  return (
    <div className="fetch-time">
      <h3 className="fetch-time__title">Afhalen</h3>
      <span className="fetch-time__explanation">Kies het gewenste afhaalmoment</span>
      <div className="fetch-time__options">
        <button className="fetch-time__select fetch-time__select--calendar">Datum</button>
        <DatePicker />
        <div className={joinClassNames(['fetch-time__select', rangeHoursExpanded && 'fetch-time__select--active'])}>
          <div className="select__trigger" onClick={(): void => setRangeHoursExpanded(!rangeHoursExpanded)}>
            {selectedHour > -1 ? `${selectedHour < 10 ? 0 : ''}${selectedHour}:00` : 'Kies een uur'}
          </div>
          {rangeHoursExpanded && (
            <div className="select__options">
              {range(0, 24)
                .filter(allowedHour)
                .map((i) => (
                  <button
                    className="select__option"
                    onClick={(): void => handleRangeHourClick(i)}
                    key={generateKey(['hour', i.toString()])}
                  >
                    {`${i < 10 ? 0 : ''}${i}:00`}
                  </button>
                ))}
            </div>
          )}
        </div>
        {selectedHour > -1 && (
          <div className="fetch-time__slots" ref={timeSlots}>
            {showTimeSlot(selectedHour, 0) && (
              <button
                onClick={(): void => handleRangeClick(selectedHour, 0)}
                className={`fetch-time__time-slot ${
                  selectedRange(selectedHour, 0) ? 'fetch-time__time-slot--active' : ''
                }`}
              >{`${selectedHour < 10 ? 0 : ''}${selectedHour}:00`}</button>
            )}
            {showTimeSlot(selectedHour, 15) && (
              <button
                onClick={(): void => handleRangeClick(selectedHour, 15)}
                className={`fetch-time__time-slot ${
                  selectedRange(selectedHour, 15) ? 'fetch-time__time-slot--active' : ''
                }`}
              >{`${selectedHour < 10 ? 0 : ''}${selectedHour}:15`}</button>
            )}
            {showTimeSlot(selectedHour, 30) && (
              <button
                onClick={(): void => handleRangeClick(selectedHour, 30)}
                className={`fetch-time__time-slot ${
                  selectedRange(selectedHour, 30) ? 'fetch-time__time-slot--active' : ''
                }`}
              >{`${selectedHour < 10 ? 0 : ''}${selectedHour}:30`}</button>
            )}
            {showTimeSlot(selectedHour, 45) && (
              <button
                onClick={(): void => handleRangeClick(selectedHour, 45)}
                className={`fetch-time__time-slot ${
                  selectedRange(selectedHour, 45) ? 'fetch-time__time-slot--active' : ''
                }`}
              >{`${selectedHour < 10 ? 0 : ''}${selectedHour}:45`}</button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FetchTime;
