import React from 'react';
import states from '../types/states';
import Orders from '../components/Orders';
import ActionBar from '../components/ActionBar';
import { validateCheckoutPage } from '../components/utlils';

const Basket: React.FC<states.BasketState> = (props: states.BasketState) => {
  return (
    <div className="basket">
      <h1 className="basket__title">Winkelwagen</h1>
      <div className="basket__orders">
        <Orders {...props} />
      </div>
      <ActionBar
        previous={{
          link: '/',
          title: 'Terug',
        }}
        next={{
          link: '/afrekenen',
          title: 'Afrekenen',
        }}
        requireValidation={true}
        nextIsValid={validateCheckoutPage(props, 'afrekenen')}
      />
    </div>
  );
};

export default Basket;
