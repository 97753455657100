import { select, put, takeLatest, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import states from '../types/states';
import { updateTotalPrice, ADD_ORDER_LINE, REMOVE_ORDER_LINE } from '../actions';
import extensions from '../extensions';

function* calculateTotalPrice(): SagaIterator {
  const state: states.State = yield select();
  const orderLines = state.basket.orders;

  let price = 0;
  orderLines.forEach((orderLine) => (price += extensions.calculatePriceOrderLine100TimesToLarge(orderLine)));

  yield put(updateTotalPrice(price / 100));
}

function* watchAddOrderLine(): SagaIterator {
  yield takeLatest(ADD_ORDER_LINE, calculateTotalPrice);
}

function* watchRemoveOrderLine(): SagaIterator {
  yield takeLatest(REMOVE_ORDER_LINE, calculateTotalPrice);
}

export default function* totalSaga(): unknown {
  yield all([watchAddOrderLine(), watchRemoveOrderLine()]);
}
