import { takeLatest, all, call, put } from 'redux-saga/effects';
import api from '../api';
import { REQUEST_PRODUCTS, receiveProducts, fetchProductsError, requestOpeningHours } from '../actions';
import server from '../types/server';
import models from '../types/models';
import extensions from '../extensions';
import { SagaIterator } from 'redux-saga';

function* fetchProducts(): SagaIterator {
  try {
    const serverProducts: server.Product[] = yield call(api.products);
    const products = serverProducts.map((product) => extensions.serverResponseProductToProduct(product));
    const categories = serverProducts
      .map((product) => extensions.serverResponseProductToCategory(product))
      .filter((category, index, array) => array.findIndex((c) => c.id === category.id) === index);
    categories.sort((a: models.Category, b: models.Category) => a.id - b.id);
    yield put(receiveProducts(products, categories));
    yield put(requestOpeningHours());
  } catch (error) {
    yield put(fetchProductsError());
  }
}

export function* watchProducts(): SagaIterator {
  yield takeLatest(REQUEST_PRODUCTS, fetchProducts);
}

export default function* productSaga(): unknown {
  yield all([watchProducts()]);
}
