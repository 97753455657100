import models from '../../types/models';
import actions from '../../types/actions';
import { RECEIVE_OPENINGHOURS } from '../../actions';

const initialState: models.OpeningHours[] = [];

const casualOpenings = (state = initialState, action: actions.ActionTypes): models.OpeningHours[] => {
  switch (action.type) {
    case RECEIVE_OPENINGHOURS:
      return action.openingHours;
    default:
      return state;
  }
};

export default casualOpenings;
