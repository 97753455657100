import React from 'react';
import propTypes from '../types/props';

const ProductBlock: React.FC<propTypes.ProductBlockProps> = (props: propTypes.ProductBlockProps) => {
  const { product, add, handleButtonClick } = props;

  return (
    <div className="product-block" onClick={(): void => handleButtonClick()}>
      <picture className="product-block__image-frame">
        <img className="product-block__image" alt={product.name} src={product.image} />
      </picture>
      <div className="product-block__content">
        <h3 className="product-block__name" dangerouslySetInnerHTML={{ __html: product.name }} />
      </div>
      <div className="product-block__shopping">
        <span className="product-block__price">&euro;{product.price} </span>
        {add ? (
          <button className="product-block__expand" onClick={(): void => handleButtonClick()}>
            <svg className="product-block__icon">
              <use xlinkHref="/icons/checkout-flow.svg#plus-icon" />
            </svg>
          </button>
        ) : (
          <button className="product-block__collapse" onClick={(): void => handleButtonClick()}>
            <svg className="product-block__icon product-block__icon--cross">
              <use xlinkHref="/icons/checkout-flow.svg#plus-icon" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductBlock;
