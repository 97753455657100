import server from '../types/server';

export const callApi = (
  action: RequestInfo,
  method: string,
  body?: server.RequestTypes,
): Promise<server.ResponseTypes> => {
  const request: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (typeof body !== 'undefined') {
    request.body = JSON.stringify(body);
  }
  return new Promise((resolve) => {
    fetch(action, request).then((response) => {
      response.json().then((result: server.ResponseTypes) => {
        resolve(result);
      });
    });
  });
};

export const host = 'http://bestelplatform-test.lothar.be';
