import React from 'react';
import propTypes from '../types/props';
import { useDispatch } from 'react-redux';
import actions from '../types/actions';
import { updateSelectedProductOption } from '../actions';

const MultipleOptions: React.FC<propTypes.Option> = (props: propTypes.Option) => {
  const { option, optionSet } = props;
  const dispatch = useDispatch();
  return (
    <div className="multiple-option">
      <label htmlFor={option.id.toString()} className="multiple-option__label">
        <input
          type="checkbox"
          className="multiple-option__input"
          name={option.name}
          id={option.id.toString()}
          checked={option.selected}
          onChange={(): actions.UpdateSelectedProductOptionAction =>
            dispatch(updateSelectedProductOption(optionSet, option))
          }
        />
        <span
          className="multiple-option__copy"
          dangerouslySetInnerHTML={{
            __html:
              option.name +
              (option.price > 0 ? ' (+&euro;' + (Math.round(option.price * 100) / 100).toFixed(2) + ')' : ''),
          }}
        />
      </label>
    </div>
  );
};

export default MultipleOptions;
