import React from 'react';
import models from '../types/models';
import { useDispatch } from 'react-redux';
import actions from '../types/actions';
import { removeOrderLine } from '../actions';
import extensions from '../extensions';
import OrderLineOptions from './OrderLineOptions';

const OrderLine: React.FC<models.OrderLine> = (props: models.OrderLine) => {
  const { product } = props;
  const dispatch = useDispatch();

  const showOptions = (product: models.Product): boolean => {
    let length = product.ingredients.filter((ingredient) => ingredient.selected).length;
    product.options.forEach((element) => {
      length += element.options.filter((option) => option.selected).length;
    });
    return length > 0;
  };

  return (
    <div className="order-line">
      <div className="order-line__information">
        <picture className="order-line__image-frame">
          <img className="order-line__image" alt={product.name} src={product.image} />
        </picture>
        <div className="order-line__content">
          <h3 className="order-line__name" dangerouslySetInnerHTML={{ __html: product.name }} />
          {showOptions(product) && <OrderLineOptions {...product} />}
        </div>
        <div className="order-line__shopping">
          <span className="order-line__price">
            &euro;{extensions.calculatePriceOrderLine100TimesToLarge(props) / 100}{' '}
          </span>
          <button
            className="order-line__collapse"
            onClick={(): actions.BasketActionTypes => dispatch(removeOrderLine(props))}
          >
            <svg className="order-line__icon order-line__icon--cross">
              <use xlinkHref="/icons/checkout-flow.svg#plus-icon" />
            </svg>
          </button>
        </div>
      </div>
      {showOptions(product) && <OrderLineOptions {...product} />}
    </div>
  );
};

export default OrderLine;
