import React from 'react';
import { useSelector } from 'react-redux';
import states from '../types/states';
import propTypes from '../types/props';
import { Link } from 'react-router-dom';

const Menu: React.FC<propTypes.Menu> = (props: propTypes.Menu) => {
  const { show, handleMenuItemClick } = props;
  const categories = useSelector((state: states.State) => state.products.categories);
  const storeSettings = useSelector((state: states.State) => state.storeSettings);

  return show ? (
    <div className="menu">
      <section className="menu__logo">
        <img src={storeSettings.logo} alt={storeSettings.name} className="menu__image" />
      </section>
      <nav className="menu__categories">
        <h2 className="menu__title">Categorieën</h2>
        <div className="menu__divider" />
        <ul className="menu__items">
          {categories.map((category, key) => (
            <li className="menu__item" key={key}>
              <Link to="/" onClick={(): void => handleMenuItemClick(category.id)} className="menu__link">
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  ) : null;
};

export default Menu;
