import React from 'react';
import states from '../types/states';
import ActionBar from '../components/ActionBar';
import { validateCheckoutPage } from '../components/utlils';
import 'moment/locale/nl-be';
import moment from 'moment';
import { Link } from 'react-router-dom';

const CheckoutControl: React.FC<states.BasketState> = (props: states.BasketState) => {
  const formatDate = (): string => {
    const time = moment(props.fetchTime.date).startOf('day').add(props.fetchTime.range.startTime, 'seconds');
    return time.locale('nl-be').format('LLLL');
  };

  const handleNavigationClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, component: string): void => {
    if (!validateCheckoutPage(props, component)) {
      e.preventDefault();
    }
  };

  return (
    <div className="checkout">
      <h1 className="checkout__title">Controleren</h1>
      <nav className="checkout__navigation">
        <Link to="/afrekenen" className="checkout__navigation-item">
          Gegevens
        </Link>
        <Link to="/afrekenen/controleren" className="checkout__navigation-item checkout__navigation-item--active">
          Controleren
        </Link>
        <Link
          to="/afrekenen/controleren"
          onClick={(e): void => handleNavigationClick(e, 'klaar')}
          className="checkout__navigation-item"
        >
          Klaar
        </Link>
      </nav>
      {validateCheckoutPage(props, 'controleren') && (
        <div className="checkout__content">
          <div className="checkout__check-block">
            {`${props.personalDetails.firstName} ${props.personalDetails.lastName}`}
            {`${props.personalDetails.firstName} ${props.personalDetails.lastName}`.trim().length > 0 && <br />}
            {props.personalDetails.email}
            {props.personalDetails.email.trim().length > 0 && <br />}
            {props.personalDetails.phone}
          </div>
          <div className="checkout__check-block">{formatDate()}</div>
        </div>
      )}
      <ActionBar
        previous={{
          link: '/afrekenen',
          title: 'Terug',
        }}
        submitOrder={true}
        requireValidation={true}
        nextIsValid={validateCheckoutPage(props, 'controleren')}
      />
    </div>
  );
};

export default CheckoutControl;
