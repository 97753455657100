import actions from '../types/actions';
import { INITIALIZE_APPLICATION } from './constants';

export * from './constants';
export * from './products';
export * from './basket';
export * from './calendar';
export * from './store-settings';

export const initializeApp = (): actions.InitializationActionTypes => ({
  type: INITIALIZE_APPLICATION,
});
