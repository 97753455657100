import React, { useState } from 'react';
import propTypes from '../types/props';
import {
  format,
  getYear,
  getMonth,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  addWeeks,
  endOfMonth,
  eachDayOfInterval,
  getISODay,
  isSameMonth,
  getISOWeek,
  getDay,
} from 'date-fns';
import { first, range, chunk, some, isFunction } from 'lodash';
import { joinClassNames, generateKey } from './utlils';
import CalendarDay from './CalenderDay';

const Calendar: React.FC<propTypes.Calendar> = (props: propTypes.Calendar) => {
  const {
    hasPreviousButton,
    hasNextButton,
    locale,
    onDayClick,
    onNextClick,
    onPreviousClick,
    hasFixedHeight,
    disabledDays,
    disabledUntil,
    enabledDays,
    selectedDays,
    closedDays,
    specialOpenedDays,
  } = props;
  const weekStartsOn = 1;
  const focusDate = new Date(props.year, props.month);
  const firstDay = startOfWeek(startOfMonth(focusDate), { weekStartsOn });
  const lastDay = hasFixedHeight
    ? endOfWeek(addWeeks(firstDay, 5), { weekStartsOn })
    : endOfWeek(endOfMonth(focusDate), { weekStartsOn });
  const calendarDays = eachDayOfInterval({ start: firstDay, end: lastDay });
  const disabledDateStrings = disabledDays ? disabledDays.map((x) => x.toDateString()) : null;
  const enabledDateStrings = enabledDays ? enabledDays.map((x) => x.toDateString()) : null;
  const [year, setYear] = useState(getYear(focusDate));
  const [month, setMonth] = useState(getMonth(focusDate));

  const handleDayClick = (day: Date): void => {
    setYear(getYear(day));
    setMonth(getMonth(day));
    if (onDayClick) {
      onDayClick(day);
    }
  };

  const handleNextClick = (): void => {
    const nextMonth = (month + 1) % 12;
    const nextMonthsYear = nextMonth < month ? year + 1 : year;

    if (onNextClick) {
      onNextClick(nextMonthsYear, nextMonth);
    } else {
      setMonth(nextMonth);
      setYear(nextMonthsYear);
    }
  };

  const handlePreviousClick = (): void => {
    const previousMonth = (month - 1) % 12;
    const previousMonthsYear = previousMonth < month ? year - 1 : year;

    if (onPreviousClick) {
      onPreviousClick(previousMonthsYear, previousMonth);
    } else {
      setMonth(previousMonth);
      setYear(previousMonthsYear);
    }
  };

  const mapDay = (day: Date): JSX.Element => {
    const isoDay = getISODay(day);
    const dateString = day.toDateString();

    let isDisabled = false;
    if (disabledDateStrings) {
      isDisabled = disabledDateStrings.indexOf(dateString) >= 0;
    }
    if (enabledDateStrings) {
      isDisabled = enabledDateStrings.indexOf(dateString) < 0;
    }
    if (disabledUntil && day < disabledUntil) {
      isDisabled = true;
    }
    if (closedDays && !isDisabled) {
      isDisabled =
        some(closedDays, (t) => t.day === getDay(day)) &&
        !some(specialOpenedDays, (t) => format(t.date, 'dd/MM/yyyy') === format(day, 'dd/MM/yyyy'));
    }

    const isSelected = isFunction(selectedDays) && selectedDays(day);
    const isOutsideMonth = !isSameMonth(day, focusDate);
    return (
      <CalendarDay
        key={generateKey(['day', isoDay.toString()])}
        day={day}
        isSelected={isSelected}
        isDisabled={isDisabled}
        isOutsideMonth={isOutsideMonth}
        onClick={handleDayClick}
      />
    );
  };

  const mapWeek = (weekDays: Date[]): JSX.Element => {
    const isoWeek = getISOWeek(first(weekDays) || new Date());
    return (
      <div className="calendar__week" key={generateKey(['week', isoWeek.toString()])}>
        {weekDays.map(mapDay)}
      </div>
    );
  };

  return (
    <div className="calendar">
      <div className="calendar__header">
        <div className="calendar__pager">
          <div
            className={joinClassNames(['calendar__previous', hasPreviousButton ? '' : 'calendar__previous--disabled'])}
            onClick={handlePreviousClick}
          />
          <div className="calendar__current-month">
            {format(focusDate, 'MMMM', {
              locale: locale,
            })}
          </div>
          <div
            className={joinClassNames(['calendar__next', hasNextButton ? '' : 'calendar__next--disabled'])}
            onClick={handleNextClick}
          />
        </div>
        <div className={'calender__day-labels'} style={{ display: 'flex', width: '100%' }}>
          {range(0, 7).map((i) => (
            <div className="calendar__day-label" key={generateKey(['day', i.toString()])}>
              {format(calendarDays[i], 'eeeeee', {
                locale: locale,
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="calendar__body">{chunk(calendarDays, 7).map(mapWeek)}</div>
    </div>
  );
};

export default Calendar;
