import propTypes from '../../types/props';

const requiredValidator = (value: string, isRequired: boolean): boolean => {
  return value.trim() !== '' || !isRequired;
};

const emailValidator = (value: string, isEmail: boolean): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase()) || !isEmail;
};

const validate = (value: string, rules: propTypes.ValidationRules): boolean => {
  let isValid = true;
  for (const rule in rules) {
    switch (rule) {
      case 'isRequired':
        isValid = isValid && requiredValidator(value, rules[rule]);
        break;
      case 'isEmail':
        isValid = isValid && emailValidator(value, rules[rule]);
        break;
      default:
        break;
    }
  }

  return isValid;
};

export default validate;
