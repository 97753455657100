import actions from '../../../types/actions';
import { UPDATE_SELECTED_PRODUCT_QUANTITY, SET_SELECTED_PRODUCT } from '../../../actions';

const initialState = 0;

const whenUpdateQuantity = (action: actions.UpdateQuantityAction): number => {
  return action.quantity;
};

const whenInitializeQuantity = (): number => {
  return 1;
};

const selectedQuantity = (state = initialState, action: actions.ActionTypes): number => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT:
      return whenInitializeQuantity();
    case UPDATE_SELECTED_PRODUCT_QUANTITY:
      return whenUpdateQuantity(action);
    default:
      return state;
  }
};

export default selectedQuantity;
