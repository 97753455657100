import models from '../../types/models';
import actions from '../../types/actions';
import { RECEIVE_PRODUCTS } from '../../actions';

const initialState: models.Category[] = [];

const whenReceiveProducts = (action: actions.ReceiveProductsAction): models.Category[] => action.categories;

const categories = (state = initialState, action: actions.ActionTypes): models.Category[] => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return whenReceiveProducts(action);
    default:
      return state;
  }
};

export default categories;
