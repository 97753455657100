import { callApi, host } from './utils';
import server from '../types/server';

export async function products(): Promise<server.ResponseTypes> {
  return await callApi(`${host}/rest/products`, 'GET');
}

export async function openingHours(): Promise<server.ResponseTypes> {
  return await callApi(`${host}/rest/openinghours`, 'GET');
}

export async function specialOpeningHours(): Promise<server.ResponseTypes> {
  return await callApi(`${host}/rest/special-openinghours`, 'GET');
}

export async function storeSettings(): Promise<server.ResponseTypes> {
  return await callApi(`${host}/rest/store-settings`, 'GET');
}

export async function createOrder(request: server.RequestTypes): Promise<server.ResponseTypes> {
  return await callApi(`${host}/commerce/order/create`, 'POST', request);
}

export default { products, openingHours, specialOpeningHours, storeSettings, createOrder };
