import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import states from '../types/states';
import ProductOverview from '../pages/ProductOverview';
import Basket from '../pages/Basket';
import Navigation from '../components/Navigation';
import Checkout from '../pages/Checkout';
import CheckoutControl from '../pages/CheckoutControl';
import Menu from '../components/Menu';
import CheckoutFinished from '../pages/CheckoutFinished';

const CheckoutFlow: React.FC = () => {
  const products: states.ProductState = useSelector((state: states.State) => state.products);
  const basket: states.BasketState = useSelector((state: states.State) => state.basket);

  const [showMenu, setShowMenu] = useState(false);
  const contentCell = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentCell.current) {
      contentCell.current.addEventListener('scroll', (): void => {
        const productSection = document.querySelector('.product-section');
        if (
          ((productSection && productSection?.getBoundingClientRect()?.top) || 0) > 94 &&
          window.outerWidth < 1200 &&
          document.fullscreen
        ) {
          document.exitFullscreen();
        }
      });
    }
  });

  const handleHamburgerClick = (): void => {
    setShowMenu(!showMenu);
  };

  const handleMenuCategoryClick = (id: number): void => {
    setTimeout(() => {
      const target = document.getElementById(id.toString());
      if (window.outerWidth < 1200) {
        document.documentElement.requestFullscreen();
      }
      target?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 100);
    setShowMenu(false);
  };

  return (
    <Router>
      <div className="grid grid--content">
        <div className="grid__cell grid__cell--content" ref={contentCell}>
          <div className="grid__cell grid__cell--centered">
            <Switch>
              <Route path="/" exact>
                <ProductOverview {...products} />
              </Route>
              <Route path="/basket" exact>
                <Basket {...basket} />
              </Route>
              <Route path="/afrekenen" exact>
                <Checkout {...basket} />
              </Route>
              <Route path="/afrekenen/controleren" exact>
                <CheckoutControl {...basket} />
              </Route>
              <Route path="/afrekenen/klaar" exact>
                <CheckoutFinished {...basket} />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="grid__cell grid__cell--navigation">
          <Navigation handleMenuButtonClick={handleHamburgerClick} expanded={showMenu} />
        </div>
      </div>
      <Menu show={showMenu} handleMenuItemClick={(id): void => handleMenuCategoryClick(id)} />
    </Router>
  );
};

export default CheckoutFlow;
