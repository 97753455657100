import actions from '../../types/actions';
import { RECEIVE_PRODUCTS, REQUEST_PRODUCTS, FETCH_PRODUCTS_ERROR } from '../../actions';

const initialState = false;

const error = (state = initialState, action: actions.ActionTypes): boolean => {
  switch (action.type) {
    case FETCH_PRODUCTS_ERROR:
      return true;
    case REQUEST_PRODUCTS:
    case RECEIVE_PRODUCTS:
      return false;
    default:
      return state;
  }
};

export default error;
