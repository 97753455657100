import React, { useState } from 'react';
import models from '../types/models';
import MultipleOptions from './MultipleOptions';
import { useDispatch } from 'react-redux';
import { updateSelectedProductOption } from '../actions';
import { joinClassNames, generateKey } from './utlils';
import { some } from 'lodash';

const OptionSet: React.FC<models.OptionSet> = (props: models.OptionSet) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const cleanOption: models.Option = {
    id: -1,
    name: '',
    selected: false,
    price: 0,
  };
  const handleSelectChange = (id: number): void => {
    const option = id < 0 ? cleanOption : props.options[id];
    dispatch(updateSelectedProductOption(props, option));
    setExpanded(false);
  };
  return (
    <div className="product-configuration__section">
      <h4 className="product-configuration__title" dangerouslySetInnerHTML={{ __html: props.name }} />
      {props.numberOfOptions === 1 ? (
        <div
          className={joinClassNames([
            'product-configuration__select',
            expanded && 'product-configuration__select--active',
          ])}
        >
          <div className="select__trigger" onClick={(): void => setExpanded(!expanded)}>
            {some(props.options, (option) => option.selected)
              ? `${props.options.filter((option) => option.selected)[0].name}`
              : props.name}
          </div>
          {expanded && (
            <div className="select__options">
              <button className="select__option" onClick={(): void => handleSelectChange(-1)}>
                {props.name}
              </button>
              {props.options.map((option, key) => (
                <button
                  className={joinClassNames(['select__option', option.selected && 'select__option--active'])}
                  onClick={(): void => handleSelectChange(key)}
                  key={generateKey(['option', key.toString()])}
                >
                  {option.name +
                    (option.price > 0 ? ' (+&euro;' + (Math.round(option.price * 100) / 100).toFixed(2) + ')' : '')}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="product-configuration__items">
          {props.options.map((option, key) => (
            <MultipleOptions key={key} option={option} optionSet={props} />
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionSet;
