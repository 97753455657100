import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import states from '../types/states';
import { sumBy } from 'lodash';
import propTypes from '../types/props';
import { joinClassNames } from './utlils';

const Navigation: React.FC<propTypes.Navigation> = (props: propTypes.Navigation) => {
  const { expanded, handleMenuButtonClick } = props;
  const storeSettings = useSelector((state: states.State) => state.storeSettings);
  const orders = useSelector((state: states.State) => state.basket.orders);
  const orderQuantity = sumBy(orders, (order) => order.quantity);
  return (
    <div className="navigation">
      <Link className="navigation__logo" to="/">
        <img src={storeSettings.logo} alt={storeSettings.name} className="navigation__image" />
      </Link>
      <button className="navigation__toggle" onClick={handleMenuButtonClick}>
        <svg className={joinClassNames(['navigation__icon', expanded && 'navigation__icon--collapse'])}>
          <use xlinkHref={`/icons/checkout-flow.svg#${expanded ? 'plus-icon' : 'hamburger'}`} />
        </svg>
      </button>
      <Link className="navigation__home" to="/" dangerouslySetInnerHTML={{ __html: storeSettings.name }} />
      <Link className="navigation__basket" to="/basket">
        <svg className="navigation__icon">
          <use xlinkHref="/icons/checkout-flow.svg#cart" />
        </svg>
        {orderQuantity > 0 && <span className="navigation__number-of-items">{orderQuantity}</span>}
      </Link>
    </div>
  );
};

export default Navigation;
