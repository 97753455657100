import models from '../../../types/models';
import actions from '../../../types/actions';
import {
  UPDATE_SELECTED_PRODUCT_INGREDIENT,
  UPDATE_SELECTED_PRODUCT_OPTION,
  SET_SELECTED_PRODUCT,
} from '../../../actions';

const initialState = {} as models.Product;

const whenSetSelectedProduct = (action: actions.SetSelectedProductAction): models.Product => action.product;

const whenUpdateSelectedProductIngredient = (
  state: models.Product,
  action: actions.UpdateSelectedProductIngredientAction,
): models.Product => {
  const ingredients = state.ingredients;
  ingredients.forEach((ingredient) => {
    if (ingredient.id === action.ingredient.id) {
      ingredient.selected = !ingredient.selected;
    }
  });
  return Object.assign({}, state, {
    ingredients: ingredients,
  });
};

const whenUpdateSelectedProductOption = (
  state: models.Product,
  action: actions.UpdateSelectedProductOptionAction,
): models.Product => {
  const optionSets = state.options;
  const index = optionSets.findIndex(
    (optionSet) =>
      action.optionSet.name === optionSet.name && action.optionSet.options.length === optionSet.options.length,
  );
  if (index < 0) {
    return state;
  }
  const options = optionSets[index].options;
  options.forEach((option) => {
    if (option.id === action.option.id) {
      option.selected = !option.selected;
    } else if (action.option.id < 0 || action.optionSet.numberOfOptions === 1) {
      option.selected = false;
    }
  });
  optionSets[index].options = options;
  return Object.assign({}, state, {
    optionSets: optionSets,
  });
};

const selectedProduct = (state = initialState, action: actions.ActionTypes): models.Product => {
  switch (action.type) {
    case SET_SELECTED_PRODUCT:
      return whenSetSelectedProduct(action);
    case UPDATE_SELECTED_PRODUCT_INGREDIENT:
      return whenUpdateSelectedProductIngredient(state, action);
    case UPDATE_SELECTED_PRODUCT_OPTION:
      return whenUpdateSelectedProductOption(state, action);
    default:
      return state;
  }
};

export default selectedProduct;
