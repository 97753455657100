import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import api from '../api';
import { REQUEST_SUBMIT_ORDER, RECEIVE_SUBMIT_ORDER } from '../actions';
import extensions from '../extensions';
import { SagaIterator } from 'redux-saga';
import states from '../types/states';

function* submitOrder(): SagaIterator {
  const state: states.State = yield select();
  const request = extensions.createOrderRequest(state.basket);
  console.log(request);
  const response = yield call(api.createOrder, request);
  console.log(response);
  yield put({ type: RECEIVE_SUBMIT_ORDER });
  window.location.href = '/afrekenen/klaar';
}

export function* watchRequestSubmit(): SagaIterator {
  yield takeLatest(REQUEST_SUBMIT_ORDER, submitOrder);
}

export default function* orderSaga(): unknown {
  yield all([watchRequestSubmit()]);
}
