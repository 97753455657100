import React, { useState } from 'react';
import { isAfter, getYear, getMonth, subDays, format, addDays } from 'date-fns';
import { nl } from 'date-fns/locale';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateFetchDate, updateFetchRange } from '../actions';
import states from '../types/states';
import Calendar from './Calendar';
import { eachDayOfInterval } from 'date-fns/esm';

const DatePicker: React.FC = () => {
  const selectedDate = useSelector((state: states.State) => state.basket.fetchTime.date);
  const openingHours = useSelector((state: states.State) => state.calendar.casualOpenings);
  const specialOpenedDays = useSelector((state: states.State) => state.calendar.specialOpeningHours);
  const daysInAdvance = useSelector((state: states.State) => state.storeSettings.numberOfPlaceOrderInAdvance) || 0;
  const [year, setYear] = useState(getYear(selectedDate));
  const [month, setMonth] = useState(getMonth(selectedDate));
  const dispatch = useDispatch();

  const handleDayClick = (day: Date): void => {
    if (isNil(selectedDate) || isAfter(day, subDays(new Date(), 1))) {
      dispatch(updateFetchDate(day));
      dispatch(updateFetchRange(0, 0));
    }
  };

  const handleNextClick = (): void => {
    const nextMonth = (month + 1) % 12;
    const nextMonthsYear = nextMonth < month ? year + 1 : year;
    setMonth(nextMonth);
    setYear(nextMonthsYear);
  };

  const handlePreviousClick = (): void => {
    const previousMonth = (month - 1) % 12;
    const previousMonthsYear = previousMonth < month ? year - 1 : year;

    setMonth(previousMonth);
    setYear(previousMonthsYear);
  };

  const selectedDays = (day: Date): boolean => {
    return format(selectedDate, 'dd/MM/yyyy') === format(day, 'dd/MM/yyyy');
  };

  const enabledDays = (): Date[] => {
    return eachDayOfInterval({ start: new Date(), end: addDays(new Date(), daysInAdvance - 1) });
  };

  return (
    <div className="date-picker">
      <Calendar
        year={year}
        month={month}
        onDayClick={handleDayClick}
        enabledDays={enabledDays()}
        disabledDays={[]}
        disabledUntil={subDays(new Date(), 1)}
        onNextClick={handleNextClick}
        hasNextButton={true}
        onPreviousClick={handlePreviousClick}
        hasPreviousButton={getMonth(new Date()) < month}
        selectedDays={selectedDays}
        locale={nl}
        closedDays={openingHours.filter((t) => t.openingHours.length === 0)}
        specialOpenedDays={specialOpenedDays.filter((t) => t.open)}
        hasFixedHeight
      />
    </div>
  );
};

export default DatePicker;
