import { combineReducers } from 'redux';
import orders from './orders';
import fetchTime from './fetch-date';
import personalDetails from './personal-details';
import total from './total';
import states from '../../types/states';

const basket = combineReducers<states.BasketState>({
  orders,
  fetchTime,
  personalDetails,
  total,
});

export default basket;
