import React from 'react';
import propTypes from '../types/props';
import { useDispatch } from 'react-redux';
import { updateSelectedProductQuantity, addOrderLine } from '../actions';

const ProductActions: React.FC<propTypes.ProductActions> = (props: propTypes.ProductActions) => {
  const dispatch = useDispatch();
  const handleQuantityChange = (value: string): void => {
    dispatch(updateSelectedProductQuantity(parseInt(value)));
  };

  const handleAddClick = (): void => {
    let newQuantity = props.orderLine.quantity;
    newQuantity = ++newQuantity;
    dispatch(updateSelectedProductQuantity(newQuantity));
  };

  const handleRemoveClick = (): void => {
    let newQuantity = props.orderLine.quantity;
    if (newQuantity > 1) {
      dispatch(updateSelectedProductQuantity(--newQuantity));
    }
  };

  const handleAddToCart = (): void => {
    dispatch(addOrderLine({ ...props.orderLine }));
  };

  return (
    <div className="product-actions">
      <span className="product-actions__copy">Aantal</span>
      <div className="product-actions__configuration">
        <button className="product-actions__remove" onClick={(): void => handleRemoveClick()} />
        <input
          className="product-actions__quantity"
          type="number"
          value={props.orderLine.quantity}
          onChange={(e): void => handleQuantityChange(e.target.value)}
        />
        <button className="product-actions__add" onClick={(): void => handleAddClick()} />
      </div>
      <button className="product-actions__add-to-cart" onClick={(): void => handleAddToCart()}>
        <svg className="product-actions__icon">
          <use xlinkHref="/icons/checkout-flow.svg#cart" />
        </svg>
      </button>
    </div>
  );
};

export default ProductActions;
