import { combineReducers } from 'redux';
import product from './selected-product';
import quantity from './selected-quantity';
import models from '../../../types/models';

const selectedOrder = combineReducers<models.OrderLine>({
  product,
  quantity,
});

export default selectedOrder;
