import actions from '../types/actions';
import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  FETCH_PRODUCTS_ERROR,
  SET_SELECTED_PRODUCT,
  UPDATE_SELECTED_PRODUCT_INGREDIENT,
  UPDATE_SELECTED_PRODUCT_OPTION,
  UPDATE_SELECTED_PRODUCT_QUANTITY,
} from './constants';
import models from '../types/models';

export const requestProducts = (): actions.ProductActionTypes => ({
  type: REQUEST_PRODUCTS,
});

export const receiveProducts = (
  products: models.Product[],
  categories: models.Category[],
): actions.ProductActionTypes => ({
  type: RECEIVE_PRODUCTS,
  products,
  categories,
});

export const fetchProductsError = (): actions.ProductActionTypes => ({
  type: FETCH_PRODUCTS_ERROR,
  error: true,
});

export const setSelectedProduct = (product: models.Product): actions.ProductActionTypes => ({
  type: SET_SELECTED_PRODUCT,
  product,
});

export const updateSelectedProductIngredient = (
  ingredient: models.Ingredient,
): actions.UpdateSelectedProductIngredientAction => ({
  type: UPDATE_SELECTED_PRODUCT_INGREDIENT,
  ingredient,
});

export const updateSelectedProductOption = (
  optionSet: models.OptionSet,
  option: models.Option,
): actions.UpdateSelectedProductOptionAction => ({
  type: UPDATE_SELECTED_PRODUCT_OPTION,
  optionSet,
  option,
});

export const updateSelectedProductQuantity = (quantity: number): actions.ProductActionTypes => ({
  type: UPDATE_SELECTED_PRODUCT_QUANTITY,
  quantity,
});
