import actions from '../types/actions';
import {
  UPDATE_PERSONAL_DETAILS_FORM,
  ADD_ORDER_LINE,
  UPDATE_TOTAL_PRICE,
  REMOVE_ORDER_LINE,
  UPDATE_FETCH_DATE,
  REQUEST_ORDER_QUANTITIES,
  RECEIVE_ORDER_QUANTITIES,
  UPDATE_FETCH_RANGE,
} from './constants';
import models from '../types/models';

export const addOrderLine = (orderLine: models.OrderLine): actions.BasketActionTypes => ({
  type: ADD_ORDER_LINE,
  orderLine,
});

export const removeOrderLine = (orderLine: models.OrderLine): actions.BasketActionTypes => ({
  type: REMOVE_ORDER_LINE,
  orderLine,
});

export const updatePersonalDetails = (name: string, value: string): actions.BasketActionTypes => ({
  type: UPDATE_PERSONAL_DETAILS_FORM,
  name,
  value,
});

export const updateTotalPrice = (price: number): actions.BasketActionTypes => ({
  type: UPDATE_TOTAL_PRICE,
  price,
});

export const updateFetchDate = (date: Date): actions.BasketActionTypes => ({
  type: UPDATE_FETCH_DATE,
  date,
});

export const updateFetchRange = (hours: number, minutes: number): actions.BasketActionTypes => ({
  type: UPDATE_FETCH_RANGE,
  hours,
  minutes,
});

export const requestOrderQuantities = (): actions.BasketActionTypes => ({
  type: REQUEST_ORDER_QUANTITIES,
});

export const receiveOrderQuantities = (quantities: models.OrderQuantity[]): actions.BasketActionTypes => ({
  type: RECEIVE_ORDER_QUANTITIES,
  quantities,
});
