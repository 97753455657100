import models from '../types/models';
import server from '../types/server';
import { Guid } from 'guid-typescript';

const productPropertyToIngredient = (property: server.ProductProperty): models.Ingredient => ({
  id: property.id,
  name: property.name,
  selected: true,
});

const mapServerOptionToOption = (option: server.Option): models.Option => ({
  id: option.id,
  name: option.name,
  price: Number(option.price__number),
  selected: false,
});

const mapServerOptionSetToOptionSet = (optionSet: server.OptionSet): models.OptionSet => ({
  numberOfOptions: Number(optionSet.max_selections) || -1,
  name: optionSet.name,
  options: optionSet.options.map((option) => mapServerOptionToOption(option)),
});

const serverResponseProductToProduct = (product: server.Product): models.Product => ({
  image:
    product.image === null || product.image.trim().length === 0
      ? `${process.env.PUBLIC_URL}/images/default.jpg`
      : product.image,
  name: product.title,
  price: parseFloat(product.price__number),
  sku: Guid.parse(product.uuid),
  options:
    product.option_sets == null ? [] : product.option_sets.map((optionSet) => mapServerOptionSetToOptionSet(optionSet)),
  description: product.body,
  currency: product.price__currency_code,
  isInBasket: false,
  category: product.category.id,
  ingredients:
    product.ingredients !== null ? product.ingredients.map((property) => productPropertyToIngredient(property)) : [],
});

export default serverResponseProductToProduct;
