import { all } from 'redux-saga/effects';
import initializeSaga from './initialize';
import productSaga from './product';
import calendarSaga from './calendar';
import totalSaga from './price';
import storeSettingsSaga from './store';
import orderSaga from './order';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* rootSaga() {
  yield all([initializeSaga(), productSaga(), calendarSaga(), totalSaga(), storeSettingsSaga(), orderSaga()]);
}
