import React from 'react';
import propTypes from '../types/props';
import { joinClassNames } from './utlils';
import { isToday, getDate } from 'date-fns';

const CalenderDay: React.FC<propTypes.CalendarDay> = (props: propTypes.CalendarDay) => {
  const { day, isSelected, isDisabled, isOutsideMonth, onClick } = props;

  const handleClick = (): void => {
    if (onClick && !isDisabled) {
      onClick(day);
    }
  };

  const classNames = joinClassNames([
    'calendar__day',
    isToday(day) && 'calendar__day--today',
    isDisabled && 'calendar__day--disabled',
    isOutsideMonth && 'calendar__day--outside-month',
    isSelected && 'calendar__day--selected',
  ]);

  return (
    <div className={classNames} onClick={handleClick}>
      <div className="calendar__day-content">{getDate(day)}</div>
    </div>
  );
};

export default CalenderDay;
