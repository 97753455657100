import actions from '../../types/actions';
import { UPDATE_TOTAL_PRICE } from '../../actions';

const initialState = 0;

const whenUpdateTotalPrice = (state: number, action: actions.UpdateTotalAction): number => {
  return action.price;
};

const total = (state = initialState, action: actions.ActionTypes): number => {
  switch (action.type) {
    case UPDATE_TOTAL_PRICE:
      return whenUpdateTotalPrice(state, action);
    default:
      return state;
  }
};

export default total;
