import React from 'react';
import models from '../types/models';

const OrderLineOptions: React.FC<models.Product> = (props: models.Product) => (
  <div className="order-line__options">
    {props.ingredients.length > 0 && (
      <div className="order-line-option">
        <span className="order-line-option__name">Ingrediënten: </span>
        <span className="order-line-option__options">
          {props.ingredients
            .filter((ingredient) => ingredient.selected)
            .map((ingredient) => ingredient.name)
            .join(', ')}
        </span>
      </div>
    )}
    {props.options
      .filter((optionSet) => optionSet.options.filter((option) => option.selected).length > 0)
      .map((option, key) => (
        <div className="order-line-option" key={key}>
          <span className="order-line-option__name">{option.name} </span>
          <span className="order-line-option__options">
            {option.options
              .filter((optionItem) => optionItem.selected)
              .map((optionItem) => optionItem.name)
              .join(', ')}
          </span>
        </div>
      ))}
  </div>
);

export default OrderLineOptions;
