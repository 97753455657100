import { combineReducers } from 'redux';
import selectedOrder from './selected-order';
import items from './items';
import categories from './categories';
import loading from './loading';
import error from './error';
import states from '../../types/states';

const products = combineReducers<states.ProductState>({
  selectedOrder: selectedOrder,
  items,
  categories,
  loading,
  error,
});

export default products;
