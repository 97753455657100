import React from 'react';
import states from '../types/states';
import ProductCategory from '../components/ProductCategory';
import Menu from '../components/Menu';

const ProductOverview: React.FC<states.ProductState> = (props: states.ProductState) => {
  const handleMenuCategoryClick = (id: number): void => {
    const target = document.getElementById(id.toString());
    console.log(target);
    target?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className="product-overview">
      <div className="product-overview__cell product-overview__cell--menu">
        <Menu handleMenuItemClick={handleMenuCategoryClick} show={true} />
      </div>
      <div className="product-overview__cell product-overview__cell--products">
        <div className="product-section">
          {props.loading ? (
            <div className="product-section__loading" />
          ) : props.error ? (
            <div className="product-section__error">Er ging iets verkeerd tijdens het ophalen van de producten</div>
          ) : (
            <div className="product-section__items">
              {props.categories.map((category, key) => (
                <ProductCategory
                  key={key}
                  selected={props.selectedOrder}
                  category={category}
                  products={props.items.filter((item) => item.category === category.id)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductOverview;
