/* eslint-disable @typescript-eslint/camelcase */
import states from '../types/states';
import server from '../types/server';
import models from '../types/models';
import calculatePriceOrderLine100TimesToLarge from './price-mapper';
import 'moment/locale/en-gb';
import moment from 'moment';

const createFieldOption = (optionSet: models.OptionSet): server.FieldOption => ({
  key: optionSet.name,
  value: optionSet.options.filter((option) => option.selected).join(', '),
});

const createIngredients = (ingredients: models.Ingredient[]): server.FieldOption => ({
  key: 'Ingrediënten',
  value: ingredients.filter((option) => option.selected).join(', '),
});

const createFieldOptions = (product: models.Product): server.FieldOption[] => {
  const fieldOptions = product.options.map((option) => createFieldOption(option));
  fieldOptions.unshift(createIngredients(product.ingredients));
  return fieldOptions;
};

const createOrderItem = (orderLine: models.OrderLine): server.OrderItem => ({
  unit_price: {
    currency_code: 'EUR',
    number: calculatePriceOrderLine100TimesToLarge(orderLine) / orderLine.quantity / 100,
  },
  purchased_entity: {
    uid: orderLine.product.sku,
  },
  field_options: createFieldOptions(orderLine.product),
});

const createOrderRequest = (basket: states.BasketState): server.Order => ({
  mail: basket.personalDetails.email,
  field_pickup: moment(basket.fetchTime.date)
    .locale('en-gb')
    .startOf('day')
    .add(basket.fetchTime.range.startTime, 'seconds'),
  order_items: basket.orders.map((orderLine) => createOrderItem(orderLine)),
});

export default createOrderRequest;
