import { combineReducers } from 'redux';
import casualOpenings from './casual-openings';
import specialOpeningHours from './special-openings';
import states from '../../types/states';

const calendar = combineReducers<states.CalendarState>({
  casualOpenings,
  specialOpeningHours,
});

export default calendar;
