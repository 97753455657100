import React from 'react';
import states from '../types/states';
import OrderLine from './OrderLine';

const Orders: React.FC<states.BasketState> = (props: states.BasketState) => {
  return (
    <div className="orders">
      {props.orders.map((order, key) => (
        <OrderLine key={key} {...order} />
      ))}
    </div>
  );
};

export default Orders;
