import { join, compact, kebabCase } from 'lodash';
import validate from './validator';
import states from '../../types/states';
import models from '../../types/models';
import moment from 'moment';

export const joinClassNames = (parts: (string | boolean)[]): string => join(compact(parts), ' ');
export const generateKey = (parts: string[]): string =>
  join(
    parts.map((part) => kebabCase(part)),
    '_',
  );

const fetchDateIsLaterThanCurrentDate = (fetchDate: models.FetchDate): boolean =>
  moment(fetchDate.date).startOf('day').add(fetchDate.range.startTime, 'seconds') > moment();

export const validateCheckoutPage = (basket: states.BasketState, page: string): boolean => {
  switch (page.toLowerCase()) {
    case 'afrekenen':
      return basket.orders.length > 0;
    case 'controleren':
      return (
        basket.orders.length > 0 &&
        validate(basket.personalDetails.email, { isRequired: true, isEmail: true }) &&
        fetchDateIsLaterThanCurrentDate(basket.fetchTime)
      );
    default:
      return true;
  }
};

export default validate;
