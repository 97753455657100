import React from 'react';
import propTypes from '../types/props';
import Product from './Product';

const ProductCategory: React.FC<propTypes.ProductCategoryProps> = (props: propTypes.ProductCategoryProps) => {
  const { products, category, selected } = props;
  return (
    <div className="product-category" id={category.id.toString()}>
      <h2 className="product-category__title">{category.name}</h2>
      <div className="product-category__items">
        {products.map((product, key) => (
          <Product
            product={selected?.product?.sku === product?.sku ? selected.product : product}
            selected={selected?.product?.sku === product.sku}
            orderLine={selected}
            add
            key={key}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductCategory;
