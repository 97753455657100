import actions from '../types/actions';
import {
  REQUEST_OPENINGHOURS,
  RECEIVE_OPENINGHOURS,
  REQUEST_SPECIAL_OPENINGHOURS,
  RECEIVE_SPECIAL_OPENINGHOURS,
} from './constants';
import models from '../types/models';

export const requestOpeningHours = (): actions.CalendarActionTypes => ({
  type: REQUEST_OPENINGHOURS,
});

export const receiveOpeningHours = (openingHours: models.OpeningHours[]): actions.CalendarActionTypes => ({
  type: RECEIVE_OPENINGHOURS,
  openingHours,
});

export const requestSpecialOpeningHours = (): actions.CalendarActionTypes => ({
  type: REQUEST_SPECIAL_OPENINGHOURS,
});

export const receiveSpecialOpeningHours = (
  specialOpeningHours: models.SpecialOpeningHours[],
): actions.CalendarActionTypes => ({
  type: RECEIVE_SPECIAL_OPENINGHOURS,
  specialOpeningHours,
});
