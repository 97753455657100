import models from '../../types/models';
import actions from '../../types/actions';
import { UPDATE_PERSONAL_DETAILS_FORM } from '../../actions';

const initialState: models.PersonalDetails = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  onlinePayment: false,
};

const whenUpdateForm = (
  state: models.PersonalDetails,
  action: actions.UpdatePersonalDetailsAction,
): models.PersonalDetails => {
  const personalDetails = { ...state };
  personalDetails[action.name] = action.value;
  return personalDetails;
};

const personalDetails = (state = initialState, action: actions.ActionTypes): models.PersonalDetails => {
  switch (action.type) {
    case UPDATE_PERSONAL_DETAILS_FORM:
      return whenUpdateForm(state, action);
    default:
      return state;
  }
};

export default personalDetails;
