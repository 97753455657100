import models from '../../types/models';
import actions from '../../types/actions';
import { ADD_ORDER_LINE, REMOVE_ORDER_LINE } from '../../actions';

const initialState: models.OrderLine[] = [];

const whenAddOrderLine = (state = initialState, action: actions.AddOrderLineAction): models.OrderLine[] => {
  const orderLines = state;
  const index = orderLines.findIndex(
    (orderLine) => JSON.stringify(orderLine.product) === JSON.stringify(action.orderLine.product),
  );
  if (index < 0) {
    orderLines.push(JSON.parse(JSON.stringify(action.orderLine)));
  } else {
    const orderLine = { ...orderLines[index] };
    orderLine.quantity += action.orderLine.quantity;
    orderLines[index] = orderLine;
  }
  return orderLines;
};

const whenRemoveOrderLine = (state = initialState, action: actions.RemoveOrderLineAction): models.OrderLine[] => {
  const orderLines = state;
  const index = orderLines.findIndex(
    (orderLine) => JSON.stringify(orderLine.product) === JSON.stringify(action.orderLine.product),
  );
  if (index < 0) {
    return orderLines;
  }
  orderLines.splice(index, 1);
  return orderLines.splice(index, 1);
};

const orders = (state = initialState, action: actions.ActionTypes): models.OrderLine[] => {
  switch (action.type) {
    case ADD_ORDER_LINE:
      return whenAddOrderLine(state, action);
    case REMOVE_ORDER_LINE:
      return whenRemoveOrderLine(state, action);
    default:
      return state;
  }
};

export default orders;
