import React from 'react';
import models from '../types/models';
import InputText from './InputText';
import propTypes from '../types/props';
import { useDispatch } from 'react-redux';
import { updatePersonalDetails } from '../actions/basket';

const PersonalDetails: React.FC<models.PersonalDetails> = (props: models.PersonalDetails) => {
  const dispatch = useDispatch();

  const firstNameFormControls: propTypes.FormControl = {
    name: 'firstName',
    value: props.firstName,
    label: 'Uw voornaam',
    placeholder: 'Voornaam',
    touched: false,
    valid: false,
    validationRules: { isRequired: false, isEmail: false },
    onChangeInput: (name, value) => dispatch(updatePersonalDetails(name, value)),
  };

  const lastNameFormControls: propTypes.FormControl = {
    name: 'lastName',
    value: props.lastName,
    label: 'Uw familienaam',
    placeholder: 'Familienaam',
    touched: false,
    valid: false,
    validationRules: { isRequired: false, isEmail: false },
    onChangeInput: (name, value) => dispatch(updatePersonalDetails(name, value)),
  };

  const emailFormControls: propTypes.FormControl = {
    name: 'email',
    value: props.email,
    label: 'Uw emailadres',
    placeholder: 'Emailaddres',
    touched: false,
    valid: false,
    validationRules: { isRequired: true, isEmail: true },
    onChangeInput: (name, value) => dispatch(updatePersonalDetails(name, value)),
  };

  const phoneFormControls: propTypes.FormControl = {
    name: 'phone',
    value: props.phone,
    label: 'Uw telefoonnummer',
    placeholder: 'Telefoonnummer',
    touched: false,
    valid: false,
    validationRules: { isRequired: false, isEmail: false },
    onChangeInput: (name, value) => dispatch(updatePersonalDetails(name, value)),
  };

  return (
    <div className="personal-details">
      <h3 className="personal-details__title">Klantgegevens</h3>
      <form className="form" noValidate={true}>
        <InputText {...firstNameFormControls} />
        <InputText {...lastNameFormControls} />
        <InputText {...emailFormControls} />
        <InputText {...phoneFormControls} />
      </form>
    </div>
  );
};

export default PersonalDetails;
