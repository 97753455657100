import React from 'react';
import states from '../types/states';
import ActionBar from '../components/ActionBar';
import { validateCheckoutPage } from '../components/utlils';
import { Link } from 'react-router-dom';

const CheckoutFinished: React.FC<states.BasketState> = (props: states.BasketState) => {
  return (
    <div className="checkout">
      <h1 className="checkout__title">Controleren</h1>
      <nav className="checkout__navigation">
        <Link to="/afrekenen" className="checkout__navigation-item">
          Gegevens
        </Link>
        <Link to="/afrekenen/controleren" className="checkout__navigation-item ">
          Controleren
        </Link>
        <Link to="/afrekenen/controleren" className="checkout__navigation-item checkout__navigation-item--active">
          Klaar
        </Link>
      </nav>
      {validateCheckoutPage(props, 'controleren') && (
        <div className="checkout__content">
          <div className="checkout__check-block">Uw bestelling werd doorgegeven</div>
        </div>
      )}
      <ActionBar
        previous={{
          link: '/',
          title: 'Nieuwe bestelling',
        }}
      />
    </div>
  );
};

export default CheckoutFinished;
