import serverResponseProductToProduct from './product-mapper';
import openinghoursMapper from './openinghours-mapper';
import serverResponseProductToCategory from './category-mapper';
import calculatePriceOrderLine100TimesToLarge from './price-mapper';
import storeSettingsResponseToStoreSettings from './store-settings-mapper';
import createOrderRequest from './order-request-mapper';

export default {
  serverResponseProductToProduct,
  openinghoursMapper,
  serverResponseProductToCategory,
  calculatePriceOrderLine100TimesToLarge,
  storeSettingsResponseToStoreSettings,
  createOrderRequest,
};
