import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import rootReducer from './reducers';
import { initializeApp } from './actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import CheckoutFlow from './containers';
import './App.scss';

const App: React.FC = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  store.dispatch(initializeApp());
  return (
    <Provider store={store}>
      <CheckoutFlow />
    </Provider>
  );
};

export default App;
