import models from '../../types/models';
import actions from '../../types/actions';
import {
  UPDATE_FETCH_DATE,
  RECEIVE_OPENINGHOURS,
  RECEIVE_SPECIAL_OPENINGHOURS,
  UPDATE_FETCH_RANGE,
} from '../../actions';
import { addDays, getDay } from 'date-fns';
import moment from 'moment';

const initialState: models.FetchDate = {
  date: new Date(),
  range: {
    startTime: new Date().getHours(),
    endTime: new Date().getHours(),
  },
};

const checkOpeningHours = (
  openingHours: models.OpeningHours,
  action: actions.ReceiveOpeningHoursAction,
  currentDay: number,
  addDaysToCurrentDay: number,
): models.UpdateFetchDateWithOpeningHours => {
  openingHours = action.openingHours.filter((t) => t.day === addDaysToCurrentDay % 7)[0];
  addDaysToCurrentDay += 1;
  return { openingHours, addDaysToCurrentDay };
};

const whenUpdateFetchDate = (state: models.FetchDate, action: actions.UpdateFetchDate): models.FetchDate => {
  return Object.assign({}, state, {
    date: action.date,
  });
};

const whenReceiveOpeningHours = (
  state: models.FetchDate,
  action: actions.ReceiveOpeningHoursAction,
): models.FetchDate => {
  const currentDay = getDay(new Date());
  let openingHours = action.openingHours.filter((t) => t.day === currentDay)[0];
  const storeClosed =
    openingHours.openingHours.length === 0 ||
    moment().diff(moment().startOf('day'), 'seconds') >
      openingHours.openingHours[openingHours.openingHours.length - 1].startTime;
  if (storeClosed) {
    openingHours.openingHours = [];
  }
  let addDaysToCurrentDay = currentDay + 1;
  while (openingHours.openingHours.length === 0 && currentDay !== addDaysToCurrentDay % 7) {
    ({ openingHours, addDaysToCurrentDay } = checkOpeningHours(openingHours, action, currentDay, addDaysToCurrentDay));
  }
  return Object.assign({}, state, {
    date: addDays(new Date(), (addDaysToCurrentDay % 7) - 1 - currentDay),
  });
};

const whenReceiveSpecialOpeningHours = (
  state: models.FetchDate,
  action: actions.ReceiveSpecialOpeningHoursAction,
): models.FetchDate => {
  const openingHours = action.specialOpeningHours.filter((t) => t.open);
  if (openingHours.length === 0) {
    return state;
  }
  if (openingHours[0].date < state.date) {
    const ranges = openingHours[0].openingHours || [];
    const storeClosed =
      ranges.length > 0 && moment().diff(moment().startOf('day'), 'seconds') > ranges[ranges.length - 1].startTime;

    if (!storeClosed) {
      return Object.assign({}, state, {
        date: openingHours[0].date,
      });
    }
  }

  return state;
};

const whenUpdateFetchRange = (state: models.FetchDate, action: actions.UpdateFetchRange): models.FetchDate => {
  const minutes = action.hours * 60 + action.minutes;

  const range: models.HoursRange = {
    startTime: minutes * 60,
    endTime: (minutes + 14) * 60,
  };

  return Object.assign({}, state, {
    range: range,
  });
};

const fetchDate = (state = initialState, action: actions.ActionTypes): models.FetchDate => {
  switch (action.type) {
    case RECEIVE_OPENINGHOURS:
      return whenReceiveOpeningHours(state, action);
    case RECEIVE_SPECIAL_OPENINGHOURS:
      return whenReceiveSpecialOpeningHours(state, action);
    case UPDATE_FETCH_DATE:
      return whenUpdateFetchDate(state, action);
    case UPDATE_FETCH_RANGE:
      return whenUpdateFetchRange(state, action);
    default:
      return state;
  }
};

export default fetchDate;
