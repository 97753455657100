import server from '../types/server';
import models from '../types/models';

const storeSettingsResponseToStoreSettings = (storeSettings: server.StoreSettings): models.StoreSettings => ({
  logo: storeSettings.store_logo,
  name: storeSettings.store_name,
  maxAllowedOrders: Number(storeSettings.pickups_per_quarter),
  numberOfPlaceOrderInAdvance: Number(storeSettings.pickup_days_in_advance),
});

export default storeSettingsResponseToStoreSettings;
