import actions from '../../types/actions';
import models from '../../types/models';
import { RECEIVE_STORE_SETTINGS } from '../../actions';

const initialState: models.StoreSettings = {
  logo: '',
  name: ``,
  numberOfPlaceOrderInAdvance: null,
  maxAllowedOrders: null,
};

const whenReceiveStoreSettings = (action: actions.ReceiveStoreSettingsAction): models.StoreSettings => {
  document.title = `${action.settings.name} | Ik koop hier`;
  return action.settings;
};

const storeSettings = (state = initialState, action: actions.ActionTypes): models.StoreSettings => {
  switch (action.type) {
    case RECEIVE_STORE_SETTINGS:
      return whenReceiveStoreSettings(action);
    default:
      return state;
  }
};

export default storeSettings;
