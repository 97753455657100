import actions from '../types/actions';
import models from '../types/models';
import { REQUEST_STORE_SETTINGS, RECEIVE_STORE_SETTINGS } from './constants';

export const requestStoreSettings = (): actions.StoreActionTypes => ({
  type: REQUEST_STORE_SETTINGS,
});

export const receiveStoreSettings = (settings: models.StoreSettings): actions.StoreActionTypes => ({
  type: RECEIVE_STORE_SETTINGS,
  settings,
});
