import React, { useState } from 'react';
import propTypes from '../types/props';
import validate from './utlils/validator';

const InputText: React.FC<propTypes.FormControl> = (props: propTypes.FormControl) => {
  const [touched, setTouched] = useState(false);
  return (
    <div className="form__item">
      <label
        htmlFor={props.name}
        className="form__label"
        dangerouslySetInnerHTML={{ __html: props.label + (props.validationRules.isRequired ? ' *' : '') }}
      />
      <input
        type="text"
        name={props.name}
        className={
          'form__input' +
          (validate(props.value, props.validationRules)
            ? ' from__input--valid'
            : touched
            ? ' form__input--invalid'
            : '')
        }
        placeholder={props.placeholder}
        required={props.validationRules.isRequired}
        value={props.value}
        onBlur={(): void => setTouched(true)}
        onChange={(e): void => props.onChangeInput(e.target.name, e.target.value)}
      />
    </div>
  );
};
export default InputText;
