import React from 'react';
import { useDispatch } from 'react-redux';
import actions from '../types/actions';
import { updateSelectedProductIngredient } from '../actions';
import models from '../types/models';

const Ingredient: React.FC<models.Ingredient> = (props: models.Ingredient) => {
  const dispatch = useDispatch();

  return (
    <div className="ingredient">
      <label htmlFor={props.id.toString()} className="ingredient__label">
        <input
          type="checkbox"
          className="ingredient__input"
          name={props.name}
          id={props.id.toString()}
          checked={props.selected}
          onChange={(): actions.UpdateSelectedProductIngredientAction =>
            dispatch(updateSelectedProductIngredient(props))
          }
        />
        <span className="ingredient__copy" dangerouslySetInnerHTML={{ __html: props.name }} />
      </label>
    </div>
  );
};

export default Ingredient;
