import models from '../types/models';

const getOptionsAdditionalPrices = (options: models.Option[]): number => {
  let chosenOptionPrices = 0;
  options
    .filter((option) => option.price > 0 && option.selected)
    .forEach((option) => (chosenOptionPrices += option.price * 100));
  return chosenOptionPrices;
};

const getOptionSetAdditionalPrices = (optionSets: models.OptionSet[]): number => {
  let additionalPrices = 0;
  optionSets.forEach((optionSets) => (additionalPrices += getOptionsAdditionalPrices(optionSets.options)));

  return additionalPrices;
};

const calculatePriceOrderLine100TimesToLarge = (orderLine: models.OrderLine): number => {
  let subTotal = orderLine.product.price * 100;
  subTotal += getOptionSetAdditionalPrices(orderLine.product.options);
  subTotal *= orderLine.quantity;
  return subTotal;
};

export default calculatePriceOrderLine100TimesToLarge;
