import React from 'react';
import propTypes from '../types/props';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { REQUEST_SUBMIT_ORDER } from '../actions';
import { joinClassNames } from './utlils';

const ActionBar: React.FC<propTypes.ActionBar> = (props: propTypes.ActionBar) => {
  const { previous, next, submitOrder, nextIsValid, requireValidation } = props;
  const dispatch = useDispatch();

  const handleSubmitOrderClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    dispatch({ type: REQUEST_SUBMIT_ORDER });
  };

  const invalid = typeof requireValidation !== 'undefined' && requireValidation && !nextIsValid;
  const handleNextClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (invalid) {
      e.preventDefault();
    }
  };

  return (
    <div className="actions">
      {previous && (
        <Link to={previous.link} className="actions__previous">
          {previous.title}
        </Link>
      )}
      {next && (
        <Link
          to={next.link}
          onClick={(e): void => handleNextClick(e)}
          className={joinClassNames(['actions__next', invalid && 'actions__next--invalid'])}
        >
          {next.title}
        </Link>
      )}
      {submitOrder && (
        <button
          onClick={(e): void => handleSubmitOrderClick(e)}
          className={joinClassNames(['actions__next', invalid && 'actions__next--invalid'])}
        >
          Afrekenen
        </button>
      )}
    </div>
  );
};

export default ActionBar;
