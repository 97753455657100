import models from '../../types/models';
import actions from '../../types/actions';
import { RECEIVE_SPECIAL_OPENINGHOURS } from '../../actions';

const initialState: models.SpecialOpeningHours[] = [];

const specialOpeningHours = (state = initialState, action: actions.ActionTypes): models.SpecialOpeningHours[] => {
  switch (action.type) {
    case RECEIVE_SPECIAL_OPENINGHOURS:
      return action.specialOpeningHours;
    default:
      return state;
  }
};

export default specialOpeningHours;
