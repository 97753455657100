import { takeLatest, all, call, put } from 'redux-saga/effects';
import api from '../api';
import { REQUEST_STORE_SETTINGS, receiveStoreSettings } from '../actions';
import server from '../types/server';
import extensions from '../extensions';
import { SagaIterator } from 'redux-saga';

function* fetchStoreSettings(): SagaIterator {
  const serverStoreSettings: server.StoreSettings = yield call(api.storeSettings);
  yield put(receiveStoreSettings(extensions.storeSettingsResponseToStoreSettings(serverStoreSettings)));
}

export function* watchStoreSettings(): SagaIterator {
  yield takeLatest(REQUEST_STORE_SETTINGS, fetchStoreSettings);
}

export default function* storeSettingsSaga(): unknown {
  yield all([watchStoreSettings()]);
}
