import { takeLatest, all, call, put } from 'redux-saga/effects';
import request from '../api';
import {
  REQUEST_OPENINGHOURS,
  receiveOpeningHours,
  receiveSpecialOpeningHours,
  REQUEST_SPECIAL_OPENINGHOURS,
  requestSpecialOpeningHours,
} from '../actions';
import extensions from '../extensions';
import { SagaIterator } from 'redux-saga';
import server from '../types/server';

function* fetchOpeningHours(): SagaIterator {
  const serverOpeningHours: server.Calendar = yield call(request.openingHours);
  const openingHours = extensions.openinghoursMapper.serverResponseOpeningHoursToOpeningHours(serverOpeningHours);
  yield put(receiveOpeningHours(openingHours));
  yield put(requestSpecialOpeningHours());
}

function* fetchSpecialOpeningHours(): SagaIterator {
  const serverOpeningHours: server.SpecialOpeningHour[] = yield call(request.specialOpeningHours);
  const openingHours = serverOpeningHours.map((openingHours) =>
    extensions.openinghoursMapper.serverResponseSpecialOpeningHoursToSpecialOpeningHours(openingHours),
  );
  yield put(receiveSpecialOpeningHours(openingHours));
}

export function* watchOpeningHours(): SagaIterator {
  yield takeLatest(REQUEST_OPENINGHOURS, fetchOpeningHours);
}

export function* watchSpecialOpeningHours(): SagaIterator {
  yield takeLatest(REQUEST_SPECIAL_OPENINGHOURS, fetchSpecialOpeningHours);
}

export default function* calendarSaga(): unknown {
  yield all([watchOpeningHours(), watchSpecialOpeningHours()]);
}
