import React from 'react';
import models from '../types/models';
import Ingredient from './Ingredient';
import OptionSet from './OptionSet';
import ProductActions from './ProductActions';

const ProductConfiguration: React.FC<models.OrderLine> = (props: models.OrderLine) => {
  const { product } = props;
  return (
    <div className="product-configuration">
      {product.ingredients.length > 0 && (
        <div className="product-configuration__section">
          <h4 className="product-configuration__title">Ingrediënten</h4>
          <div className="product-configuration__items">
            {product.ingredients.map((ingredient, key) => (
              <Ingredient {...ingredient} key={key} />
            ))}
          </div>
        </div>
      )}
      {product.options.length > 0 && product.options.map((optionSet, key) => <OptionSet {...optionSet} key={key} />)}
      <ProductActions orderLine={props} />
    </div>
  );
};

export default ProductConfiguration;
